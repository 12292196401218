<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-5">
      <div class="card p-fluid">
        <h3>Информация</h3>
        <div class="card p-fluid">
          <form @submit.prevent="submitForm">
            <div class="p-field">
              <label for="name">Название</label>
              <InputText
                id="name"
                v-model.trim="name.val"
                :required="true"
                @blur="clearValidity('name')"
                :class="!name.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="country">Страна</label>
              <Dropdown
                id="country"
                v-model="country.val"
                :options="countryList"
                :required="true"
                optionLabel="name"
                placeholder="Выберите страну"
                @blur="clearValidity('country')"
                :class="!country.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="region">Регион</label>
              <Dropdown
                id="region"
                v-model="region.val"
                :options="regionList"
                :required="true"
                optionLabel="name"
                placeholder="Выберите регион"
                :disabled="this.country.val === null"
                @blur="clearValidity('region')"
                :class="!region.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="actualAddress">Физический адрес</label>
              <InputText
                id="actualAddress"
                v-model.trim="actualAddress.val"
                :required="true"
                @blur="clearValidity('actualAddress')"
                :class="!actualAddress.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="district">Район</label>
              <InputText
                id="district"
                v-model.trim="district.val"
                :required="true"
                @blur="clearValidity('district')"
                :class="!district.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="legalAddress">Юридический адрес</label>
              <InputText
                id="legalAddress"
                v-model.trim="legalAddress.val"
                :required="true"
                @blur="clearValidity('legalAddress')"
                :class="!legalAddress.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="shir">Широта</label>
              <InputText
                id="shir"
                v-model.trim="shir.val"
                :required="true"
                @blur="clearValidity('shir')"
                :class="!shir.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="long">Долгота</label>
              <InputText
                id="long"
                v-model.trim="long.val"
                :required="true"
                @blur="clearValidity('long')"
                :class="!long.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="inn">ИНН</label>
              <InputText
                id="inn"
                v-model.trim="inn.val"
                :required="true"
                @blur="clearValidity('inn')"
                :class="!inn.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="kpp">КПП</label>
              <InputText
                id="kpp"
                v-model.trim="kpp.val"
                :required="true"
                @blur="clearValidity('kpp')"
                :class="!kpp.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label for="email">E-mail</label>
              <InputText
                type="email"
                id="email"
                @blur="clearValidity('email')"
                :class="!email.isValid ? 'p-invalid' : ''"
                v-model.trim="email.val"
                :required="true"
              />
            </div>
            <div class="p-field">
              <label for="phone">Контактный телефон</label>
              <InputMask
                mask="+7-999-999-99-99"
                id="phone"
                v-model.trim="phone.val"
                :required="true"
                @blur="clearValidity('phone')"
                :class="!phone.isValid ? 'p-invalid' : ''"
                :autoClear="false"
              />
            </div>

            <!--            <Button-->
            <!--              label="Создать клиента"-->
            <!--              @click="submitForm"-->
            <!--              :disabled="isLoading"-->
            <!--            ></Button>-->
          </form>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-7">
      <div class="card p-fluid">
        <technic-list></technic-list>
      </div>
      <div class="card p-fluid">
        <employe-list></employe-list>
      </div>
    </div>
  </div>
</template>

<script>
import TechnicList from "@/views/operator/TechnicList";
import EmployeList from "@/views/operator/EmployeList";

export default {
  name: "ClientCreate",
  components: { EmployeList, TechnicList },
  data() {
    return {
      name: {
        val: "",
        isValid: true,
      },
      country: {
        val: null,
        isValid: true,
      },
      region: {
        val: null,
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
        isValid: true,
      },
      actualAddress: {
        val: "",
        isValid: true,
      },
      district: {
        val: "",
        isValid: true,
      },
      legalAddress: {
        val: "",
        isValid: true,
      },
      shir: {
        val: "",
        isValid: true,
      },
      long: {
        val: "",
        isValid: true,
      },
      inn: {
        val: "",
        isValid: true,
      },
      kpp: {
        val: "",
        isValid: true,
      },
      isLoading: false,
      formIsValid: false,
    };
  },
  computed: {
    roleList() {
      return this.$store.getters.getRoleList;
    },
    countryList() {
      return this.$store.getters["data/countries"];
    },
    regionList() {
      if (this.country.val) {
        return this.$store.getters["data/regions"].filter(
          (r) => r.country.uid === this.country.val.uid
        );
      }
      return [];
    },
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      this.name.isValid = true;
      this.region.isValid = true;
      this.email.isValid = true;
      this.phone.isValid = true;
      this.actualAddress.isValid = true;
      this.district.isValid = true;
      this.legalAddress.isValid = true;
      this.shir.isValid = true;
      this.long.isValid = true;
      this.inn.isValid = true;
      this.kpp.isValid = true;
      if (!this.name.val) {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (!this.region.val) {
        this.region.isValid = false;
        this.formIsValid = false;
      }
      if (!this.country.val) {
        this.country.isValid = false;
        this.formIsValid = false;
      }
      if (!this.email.val) {
        this.email.isValid = false;
        this.formIsValid = false;
      }
      if (!this.phone.val) {
        this.phone.isValid = false;
        this.formIsValid = false;
      }
      if (!this.actualAddress.val) {
        this.actualAddress.isValid = false;
        this.formIsValid = false;
      }
      if (!this.district.val) {
        this.district.isValid = false;
        this.formIsValid = false;
      }
      if (!this.legalAddress.val) {
        this.legalAddress.isValid = false;
        this.formIsValid = false;
      }
      if (!this.shir.val) {
        this.shir.isValid = false;
        this.formIsValid = false;
      }
      if (!this.long.val) {
        this.long.isValid = false;
        this.formIsValid = false;
      }
      if (!this.inn.val) {
        this.inn.isValid = false;
        this.formIsValid = false;
      }
      if (!this.kpp.val) {
        this.kpp.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        name: this.name.val,
        email: this.email.val,
        phone: this.phone.val,
        region: {
          ...this.region.val,
          country: this.country.val,
        },
        actualAddress: this.actualAddress.val,
        district: this.district.val,
        legalAddress: this.legalAddress.val,
        latitude: this.shir.val,
        longitude: this.long.val,
        inn: this.inn.val,
        kpp: this.kpp.val,
      };

      try {
        const newClient = await this.$store.dispatch(
          "myprofiles/createClient",
          actionPayload
        );
        await this.$router.push(`/client/${newClient.id}`);
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Клиент ${actionPayload.name} создан!`,
          life: 6000,
        });
        this.$store.dispatch("myprofiles/loadClients");
      } catch (err) {
        const error = err.message || "Не получилось создать клиента";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при создании клиента!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
